import React, { Component, Fragment } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { doCartMath, roundTo2Decimals } from '../helpers/utility';
class OrderSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMoreInfoOpen: false,
        }
    }
    componentDidMount() {
        this.setState({ cartItems: this.props.cartItems ? this.props.cartItems : [] }, () => {
                doCartMath(this.state.cartItems,this.props.global.totalTax,this.props.global.totalShipping);
                // doCartMath(this.state.cartItems);
        })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.cartItems.length !== this.props.cartItems.length) {
            this.setState({ cartItems: this.props.cartItems }, () => {
                console.log(this.state.cartItems,"yoo")
                doCartMath(this.state.cartItems,this.props.global.totalTax,this.props.global.totalShipping);
            })
        }
        if(JSON.stringify(prevProps.global) !== JSON.stringify(this.props.global)){
            doCartMath(this.state.cartItems,this.props.global.totalTax,this.props.global.totalShipping);
        }
    }
    toggleMoreInfo = () => {
        this.setState(prevState => ({
          isMoreInfoOpen: !prevState.isMoreInfoOpen,
        }));
      };

    render() {
        const {isMoreInfoOpen} = this.state;
        let cartSubTotal = this.props.global.cartSubTotal;
        let cartTotal = this.props.global.cartTotal;
        let totalShipping = this.props.global.totalShipping;
        let totalTax = this.props.global.totalTax
        return (
            <div onClick={this.toggleMoreInfo}  id="order-summary" role="order-summary1" className={'box mb-4 p-0 navbar navbar-expand-lg d-block-desktop ' + this.props.class}>
                <div className="box-header mt-0">
                    <div>
                        <h3>Order summary</h3>
                    </div>
                    <div>
                        <button type="button" data-bs-toggle="collapse" data-target="#order-summary1" className="navbar-toggler btn-template-outlined sidebar-button"><span className="sr-only">Toggle navigation</span>
                        {isMoreInfoOpen ? (
                            <i className="fa fa-chevron-up"></i>
                            ) : (
                            <i className="fa fa-chevron-down"></i>
                        )}     
                        </button>
                    </div>
                </div>
                <div id="order-summary1" className="cart-order-summary">
                <p className="text-muted">Shipping and taxes are calculated based on the shipping address.</p>
                <div className="table-responsive">
                    <table className="table">
                        <tbody>
                            <tr>
                                <td className='font-bold'>Subtotal</td>
                                <th className="text-right"><NumberFormat isNumericString={true} decimalScale={2} value={roundTo2Decimals(parseFloat(cartSubTotal)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></th>
                            </tr>
                            {this.props.showTaxAndShipping && (
                                <Fragment>
                                    <tr>
                                        <td>Shipping and handling</td>
                                        <th className="text-right"><NumberFormat isNumericString={true} decimalScale={2} value={roundTo2Decimals(parseFloat(totalShipping)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></th>
                                    </tr>
                                    <tr>
                                        <td>Tax</td>
                                        <th className="text-right"><NumberFormat isNumericString={true} decimalScale={3} value={roundTo2Decimals(parseFloat(totalTax)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></th>
                                    </tr>
                                </Fragment>
                            )}
                            <tr className="total">
                                <td>Total</td>
                                <th className="text-right"><NumberFormat isNumericString={true} decimalScale={2} value={roundTo2Decimals(parseFloat(cartTotal)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></th>
                            </tr>
                        </tbody>
                    </table>
                </div>

                </div>
            </div>
        )
    }
}

const stateMapQuantityBar = (state) => {
    return {
        global: state.global,
        cartItems: state.cart,
    };
};

export default connect(stateMapQuantityBar)(OrderSummary);