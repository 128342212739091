import React, { Component } from 'react';
export default class Dropdown extends Component {
	constructor(props) {
		super(props);
		this.optionsChange = this.optionsChange.bind(this);
		this.state = {
			selectedOptions: {}
		}
	}
	optionsChange(event) {
		let selectedOptions = { ...this.state.selectedOptions };
		let product = []
		product = this.props.detailRef.props.products.filter(product => {
			if(product.ProductCode === event.target.selectedOptions[0].dataset.productname)
				return product
		})
		selectedOptions[event.target.name] = {
			id: event.target.value,
			name: event.target.selectedOptions[0].innerText,
			type: event.target.dataset.type,
			isProductPrice: parseInt(event.target.selectedOptions[0].dataset.isproductprice),
			price: event.target.selectedOptions[0].dataset.price || 0,
			catId: event.target.selectedOptions[0].dataset.catid,
			ProductPrice:  parseInt(event.target.selectedOptions[0].dataset.isproductprice) ? (product[0] ? product[0].ProductPrice : 0) : 0,
			ProductCode : product[0] ? product[0].ProductCode : '',
			productDescription : product[0] ? product[0].ProductDescriptionShort : '',
			ProductWeight: product[0]?  product[0].ProductWeight : 0,
			ProductID: product[0] ? product[0].ProductID : -1,
			ProductName: product[0] ? product[0].ProductName : '',

		}
		this.setState({ selectedOptions });
		this.props.detailRef.getSelectedOptions(selectedOptions)
	}
	componentDidMount() {
		let selectedOptions = {};
		if (this.props.detailRef.state.product.SelectedOptions) {
			selectedOptions = this.props.detailRef.state.product.SelectedOptions
			this.props.options.forEach(option => {
				option.options.forEach(singleOption =>{
					console.log(singleOption)
					let product2 = []
					product2 = this.props.detailRef.props.products.filter(product => {
						return product.ProductCode === singleOption.optionsDesc
					})
					singleOption.ProductPrice = product2.length > 0 ? product2[0].ProductPrice : 0
				})
			})

		} else {
			this.props.options.forEach(option => {
				let product = []
				let index = 0
				let optionDefault = option.options.filter(op => {
					return op.defaultSelected === 1
				})
				if(optionDefault.length === 0){
					optionDefault = option.options
				}
				product = this.props.detailRef.props.products.filter(product => {
					return product.ProductCode === optionDefault[0].optionsDesc
				})
				option.options.forEach((singleOption,ind) =>{
					let product2 = []
					product2 = this.props.detailRef.props.products.filter(product => {
						return product.ProductCode === singleOption.optionsDesc
					})
					singleOption.ProductPrice = product2.length > 0 ? product2[0].ProductPrice : 0
					if(singleOption.defaultSelected)
						index = ind
				})
				option.ProductPrice =product[0] ?  product[0].ProductPrice : 0
				selectedOptions[option.key] = {
					id: option.options[index].id,
					name: option.options[index].optionsDesc,
					type: option.label,
					price: option.priceDiff || 0,
					catId: option.options[index].optionCatId,
					isProductPrice: option.options[index].isProductPrice,
					ProductPrice:  option.options[index].isProductPrice ?(product[0] ?  product[0].ProductPrice : 0) : 0,
					ProductCode : product[0] ? product[0].ProductCode : '',
					productDescription :product[0] ?  product[0].ProductDescriptionShort : '',
					ProductWeight:product[0] ?  product[0].ProductWeight : 0,
					ProductID: product[0] ? product[0].ProductID : -1,
					ProductName:product[0] ? product[0].ProductName : 0,
				}
			})
		}
		this.setState({ selectedOptions });
		this.props.detailRef.getSelectedOptions(selectedOptions)
	}

	createLabel = (option,priceDiff) => {
		return (option.optionName.toLowerCase() === "none" ? option.optionName : option.optionName + " [" + option.optionsDesc + "]" + (option.isProductPrice ?  (option.ProductPrice? " + $" + option.ProductPrice.toFixed(2) : priceDiff): '') )
	}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps.options) !== JSON.stringify(this.props.options)) {
			let selectedOptions = {};
			if (this.props.detailRef.state.product.SelectedOptions) {
				selectedOptions = this.props.detailRef.state.product.SelectedOptions
				this.props.options.forEach(option => {
					option.options.forEach(singleOption =>{
						console.log(singleOption)
						let product2 = []
						product2 = this.props.detailRef.props.products.filter(product => {
							return product.ProductCode === singleOption.optionsDesc
						})
						singleOption.ProductPrice = product2.length > 0 ? product2[0].ProductPrice : 0
						console.log(singleOption.ProductPrice , "yoo")
	
					})
				})
			} else {
				this.props.options.forEach(option => {
					let product = []
					let index = 0
					let optionDefault = option.options.filter(op => {
						if(op.defaultSelected === 1){
							return op
						}
					})
					if(optionDefault.length === 0){
						optionDefault = option.options
					}
					product = this.props.detailRef.props.products.filter(product => {
						return product.ProductCode === optionDefault[0].optionsDesc
					})
					option.options.forEach((singleOption,ind) =>{
						if(singleOption.defaultSelected){
							index = ind
						}
						let product2 = []
						product2 = this.props.detailRef.props.products.filter(product => {
							return product.ProductCode === singleOption.optionsDesc
						})
						singleOption.ProductPrice = product2.length > 0 ? product2[0].ProductPrice : 0
					})
					option.ProductPrice = product[0] ? product[0].ProductPrice : 0
					selectedOptions[option.key] = {
						id: option.options[index].id,
						name: option.options[index].optionsDesc,
						type: option.label,
						price: option.priceDiff || 0,
						isProductPrice : option.options[index].isProductPrice,
						catId: option.options[index].optionCatId,
						ProductPrice: option.options[index].isProductPrice ?(product[0]? product[0].ProductPrice : 0) : 0,
						ProductCode: product[0]?product[0].ProductCode : '',
						productDescription : product[0]? product[0].ProductDescriptionShort : '',
						ProductWeight: product[0]? product[0].ProductWeight : 0,
						ProductID:product[0]? product[0].ProductID : -1,
						ProductName:product[0]? product[0].ProductName : '',
					}
				})
			}
			this.setState({ selectedOptions });
			this.props.detailRef.getSelectedOptions(selectedOptions)
		}
	}

	render() {
		return (
			<div>
				<hr></hr>
				<strong className="lighter-note">Choose your options</strong>
				{this.props.options.map(dropdown => {
					return (this.state.selectedOptions[dropdown.key] && <div key={dropdown.key} className="mb-3">
						<div className="row">
							<label    className="form-label col-sm-12">{dropdown.label}: </label>
							<div className="col-sm-12">
								<div className="mb-3 m-0 pe-5 items-per-select">
									<select data-type={dropdown.label} name={dropdown.key} value={this.state.selectedOptions[dropdown.key].id} onChange={this.optionsChange.bind(this)} className="form-select">
										{dropdown.options.map(option => {
											let priceDiff = (option.priceDiff !== 0) ? (' [Add $' + option.priceDiff + ']') : '';
											return <option key={option.id} data-isProductPrice={option.isProductPrice} data-productName={option.optionsDesc} data-catId ={option.optionCatId} data-price={option.priceDiff || 0} value={option.id}>{option && option.optionName ? this.createLabel(option,priceDiff):""}</option>
										})}
									</select>
								</div>
							</div>
						</div>
					</div>)
				})}
			</div>
		)
	}
}
