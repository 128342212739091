import React, { Component } from 'react';
import Scroll from 'react-scroll';
import { API_LOGIN, API_PUBLIC_KEY } from '../../helpers/constants';
import { required, validCard, cardExpiry, cardCVC } from '../../helpers/form-validation';
import Cards from 'react-credit-cards';
import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from '../../helpers/utility';
import { connect } from 'react-redux';
import { getOrderForExternal, placeOrderForExternal } from '../../actions/orderAction';
import store from '../../store';
import { TO_CHECKOUT_BILLING_ADDRESS, TO_EXTERNAL_CHECKOUT, TO_EXTERNAL_CHECKOUT_BILLING_ADDRESS } from '../../helpers/routesConstants';
import NavLink from 'react-router-dom/NavLink';
import { useNavigate } from 'react-router-dom';
import { EXTERNAL_ORDER_ERROR, ORDER_ERRORED } from '../../helpers/actionConstants';
class  ExternalCheckoutContainer extends Component {
    constructor(props){
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.myFunction = this.myFunction.bind(this);
        this.sendPaymentDataToAnet = this.sendPaymentDataToAnet.bind(this);
        this.acceptPaymentResponsehandler = this.acceptPaymentResponsehandler.bind(this);
        this.state = {
            orderPlaced: false,
            order:{},
            orderId:null,
            orderhash:null,
            paymentInfo: {
                number: '',
                name: '',
                expiry: '',
                cvc: '',
            },
            error:{},
            orderRes: {},
            focused: 'number',
            inProcess: false,
            errorMessage: null,
        }
    }
    componentWillMount() {
        Scroll.animateScroll.scrollToTop();
    }
    sendPaymentDataToAnet() {
        this.setState({ inProcess: true, errorMessage: null, orderRes: {} })
        let authData = {};
        authData.clientKey = API_PUBLIC_KEY;
        authData.apiLoginID = API_LOGIN;
        let cardData = {};
        cardData.cardNumber = this.state.paymentInfo.number.replace(/\s/g, '');
        cardData.month = this.state.paymentInfo.expiry.slice(0, 2);
        cardData.year = '20' + this.state.paymentInfo.expiry.slice(3, 5);
        cardData.cardCode = this.state.paymentInfo.cvc;
        let secureData = {};
        secureData.authData = authData;
        secureData.cardData = cardData;
        window.Accept.dispatchData(secureData, this.acceptPaymentResponsehandler);
    }
    handleChange(event) {
        let paymentInfo = { ...this.state.paymentInfo };
        if (event.target.name === 'number') {
            event.target.value = formatCreditCardNumber(event.target.value);
        } else if (event.target.name === 'expiry') {
            event.target.value = formatExpirationDate(event.target.value);
        } else if (event.target.name === 'cvc') {
            event.target.value = formatCVC(event.target.value);
        }
        paymentInfo[event.target.name] = event.target.value;
        this.setState({ paymentInfo, focused: event.target.name });
    }
    myFunction(event) {
        if (!(event.key >= 0 && event.key <= 9)) {
            event.preventDefault();
        }
    }
    acceptPaymentResponsehandler(response) {
        if (response.messages.resultCode === "Error") {
            console.log('Error: ', response);
            this.setState({ inProcess: false, errorMessage: response.messages.message[0].text })
        }
        else{
            let orderObj = {
                orderId : this.state.orderId,
                customerId : this.state.order.CustomerID,
                BillingFirstName : this.props.orderToBePlaceExternal.BillingFirstName,
                BillingLastName : this.props.orderToBePlaceExternal.BillingLastName,
                BillingAddress1 : this.props.orderToBePlaceExternal.BillingAddress1,
                BillingAddress2 : this.props.orderToBePlaceExternal.BillingAddress2,
                BillingPhoneNumber : this.props.orderToBePlaceExternal.BillingPhoneNumber,
                BillingCompanyName : this.props.orderToBePlaceExternal.BillingCompanyName,
                BillingCountry : this.props.orderToBePlaceExternal.BillingCountry,
                BillingState : this.props.orderToBePlaceExternal.BillingState,
                BillingCity : this.props.orderToBePlaceExternal.BillingCity,
                BillingPostalCode : this.props.orderToBePlaceExternal.BillingPostalCode,
            }
            orderObj.opaqueData = response.opaqueData
            store.dispatch(placeOrderForExternal(orderObj))
        }
    }
    handleUpdateSubmit = (event) => {
        event.preventDefault();
        this.form.validateAll();
        if (this.form.getChildContext()._errors.length === 0) {
            this.sendPaymentDataToAnet()
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.externalOrder !== this.props.externalOrder){
            this.setState({
                order:this.props.externalOrder,
                error:{},

            })
        }
        if(this.props.externalOrderError && prevProps.externalOrderError !== this.props.externalOrderError){
            this.setState({
                error:this.props.externalOrderError,
                order:{},
                inProcess:false
            })
            store.dispatch({ type: EXTERNAL_ORDER_ERROR, payload: {} });
        }
        if (this.props.orderPlaceError && this.props.orderPlaceError.code !== prevProps.orderPlaceError.code) {
            this.setState({ orderRes: this.props.orderPlaceError, inProcess:false }) 
            store.dispatch({ type: ORDER_ERRORED, payload: {} });
        }
    }

    componentDidMount () {
        
        let href = window.location.href
        let orderId = href.substring(href.lastIndexOf('/') + 1)
        if(Object.keys(this.props.externalOrder).length === 0){
            window.location.href = "/external-checkout-billing-address/"+orderId
        }
        let orderhash = orderId
        orderId = parseInt(atob(orderId))
        this.setState({
            orderhash:orderhash,
            orderId:orderId
        })
		store.dispatch(getOrderForExternal(orderId));
    }

    render() {
        return (
            <div id="content">
                <div className="container">
                    <div className="row">
                        <div id="checkout" className="col-xl-9 col-lg-12 col-md-12 col-sm-12 col-xs-12 checkout-page">
                            {
                                (Object.keys(this.state.error).length !==0 && this.state.error.isPaid) && (
                                    <div className="mt-5 mb-3 d-flex justify-content-center align-items-bottom mt-3">
                                        <p>This order has already been paid. Thank you!</p>                      
                                    </div>
                                )
                            }
                            {
                                (Object.keys(this.state.error).length !==0 && !this.state.error.isPaid) && (
                                    <div className="mt-5 mb-3 d-flex justify-content-center align-items-bottom mt-3">
                                        <p>This page in unavaiable because {this.state.error.msg}</p>                      
                                    </div>
                                )
                            }
                            {Object.keys(this.state.order).length !== 0 && (
                                <div className="text-center align-items-bottom mt-3">
                                    <p>Your Payment Amount is <b>${this.state.order && this.state.order.PaymentAmount && this.state.order.PaymentAmount.toFixed(2)} USD</b> for Order#: <b>{this.state.orderId}</b></p>                      
                                </div>
                            )}
                            {Object.keys(this.state.order).length !== 0 && (
                                <div className="box-external-margin-bottom">
                                    <ul className="nav nav-pills nav-fill">
                                        <li className="nav-item"><NavLink to={TO_EXTERNAL_CHECKOUT_BILLING_ADDRESS + this.state.orderhash} activeClassName='active' className="nav-link"> <i className="fa fa-address-card"></i><br></br>Billing Address</NavLink></li>
                                        <li className="nav-item"><NavLink to={TO_EXTERNAL_CHECKOUT + this.state.orderhash} activeClassName='active' className="nav-link"><i className="fa fa-money"></i><br></br>Payment Method</NavLink></li>
                                    </ul>
                                    <div className='box-external  border-bottom-0'>
                                        <Form ref={c => { this.form = c }} onSubmit={this.handleUpdateSubmit.bind(this)}>
                                            <div className="content">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                        <Cards
                                                            number={this.state.paymentInfo.number}
                                                            name={this.state.paymentInfo.name}
                                                            expiry={this.state.paymentInfo.expiry}
                                                            cvc={this.state.paymentInfo.cvc}
                                                            focused={this.state.focused}
                                                            callback={this.callbackHandle}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-3 padding-top-bottom">
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                                                                <Input validations={[required, validCard]} onKeyPress={this.myFunction} maxLength="19" className="form-select" type="tel" name="number" value={this.state.paymentInfo.number} onFocus={this.handleChange} onChange={this.handleChange} placeholder="Card Number"></Input>
                                                                <small className="form-text text-muted">We only accept: VISA, MASTER CARD, AMERICAN EXPRESS</small>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                                                                <Input validations={[required]} className="form-select" type="text" name="name" value={this.state.paymentInfo.name} onFocus={this.handleChange} onChange={this.handleChange} placeholder="Name"></Input>
                                                            </div>
                                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3">
                                                                <Input validations={[required, cardExpiry]} className="form-select" pattern="\d\d/\d\d" type="tel" name="expiry" value={this.state.paymentInfo.expiry} onFocus={this.handleChange} onChange={this.handleChange} placeholder="MM/YY"></Input>
                                                            </div>
                                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3">
                                                                <Input validations={[required, cardCVC]} className="form-select" pattern="\d{3,4}" type="tel" name="cvc" value={this.state.paymentInfo.cvc} onFocus={this.handleChange} onChange={this.handleChange} placeholder="CVC"></Input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mgTop30'>
                                                <button disabled={this.state.inProcess} type="submit" className="btn btn-template-outlined pull-right shiiping-btn">Place Order </button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            )}
                            {(this.state.errorMessage && <div className="alert alert-danger">
                                <strong>Error!</strong> <span>{this.state.errorMessage}</span>
                            </div>
                            )}
                            {(this.props.global.orderPlaced && <div className="alert alert-success">
                                <strong>Success!</strong> <span>Order has been placed successfully.</span>
                            </div>
                            )}
                            {(this.state.orderRes.code === 400 && this.state.orderRes.result.payment && <div className="alert alert-danger">
                                <strong>Error!</strong> <span>{this.state.orderRes.result.payment.errorMessage}</span>
                            </div>
                            )}
                            {(this.state.orderRes.code === 400 && this.state.orderRes.result.payment && this.state.orderRes.result.payment.errorCode === "65" && <div className="alert alert-danger">
                                <strong>Error!</strong> <span>Invalid billing address or credit card details.</span>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const stateMap = (state) => {
	return {
		externalOrder: state.order.externalOrder,
		externalOrderError: state.order.externalOrderError,
        orderToBePlace: state.order.orderToBePlace,
        orderProcess: state.order.orderProcess,
        isLoggedIn: state.global.isLoggedIn,
        orderPlaceError: state.global.orderPlaceError,
        global: state.global,
        cartItems: state.cart,
        orderToBePlaceExternal:state.order.orderToBePlaceExternal
	};
};

export default connect(stateMap)(ExternalCheckoutContainer);
